import { graphql, PageProps } from 'gatsby'
import * as React from 'react'
import { Layout } from '../../components/Layout'
import { CoursesGrid } from '../../components/CoursesGrid'

const CursosArchivoPage = ({
  data: {
    directus: { page, courses },
  },
}: PageProps<Queries.CursosPageQuery>) => {
  if (!page) {
    return null
  }

  return (
    <Layout pageHeader={page.page_header}>
      <div className="container my-8">
        <CoursesGrid
          className="mx-auto max-w-5xl"
          courses={(courses as []).reverse()}
          list={true}
        />
      </div>
    </Layout>
  )
}

export default CursosArchivoPage

export { PageHead as Head } from '../../components/PageHead'

export const query = graphql`
  query CursosArchivoPage {
    directus {
      page: page_cursos_archivo {
        meta_description
        page_header {
          ...PageHeader
        }
      }
      courses(filter: { status: { _eq: "published" } }, sort: "start_date") {
        ...CourseGridCard
      }
    }
  }
`
